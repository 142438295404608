<template>
	<section class="booking-form">
		<ValidationObserver ref="form" v-slot="{ handleSubmit }">
			<form @submit.prevent="handleSubmit(applyForm)">
				<h6 class="booking-form__heading">{{ $t("ProductBooking.Description") }}</h6>

				<hr class="h-divider" />

				<div class="booking-form__container">
					<!-- User name -->
					<div class="form-group question__name">
						<label for="username" class="form-group__label">{{
							$t("ProductBooking.YourFullName")
						}}</label>
						<ValidationProvider
							v-slot="{ classes, errors }"
							name="booking_full_name"
							rules="required"
						>
							<div :class="['form-group__input', classes]">
								<input
									id="username"
									v-model.trim="bookingForm.fullName"
									type="text"
									class="form-control other"
								/>
								<div class="error-mess">{{ errors[0] }}</div>
							</div>
						</ValidationProvider>
					</div>

					<!-- Phone -->
					<div class="form-group question__phone">
						<label for="phone" class="form-group__label">{{ $t("ProductBooking.Phone") }}</label>
						<div class="form-group__input">
							<ValidationProvider
								v-slot="{ classes, errors }"
								name="booking_phone_number"
								rules="required|numeric"
							>
								<div :class="[classes]">
									<input
										id="phone"
										v-model="bookingForm.phoneNumber"
										type="text"
										class="form-control other"
										:maxlength="phoneMaxLength"
										:disabled="isMobile"
										@keydown="checkValidPhone($event)"
									/>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- Birthyear -->
					<div class="form-group question__birthyear">
						<label for="birthyear" class="form-group__label">{{
							$t("ProductBooking.Birthyear")
						}}</label>
						<div class="form-group__input">
							<ValidationProvider
								v-slot="{ classes, errors }"
								name="booking_year_number"
								rules="required|numeric"
							>
								<div :class="[classes]">
									<input
										id="birthyear"
										v-model="bookingForm.birthYear"
										type="text"
										class="form-control other"
										:maxlength="maxlengthBirth"
										:disabled="isMobile"
										@keydown="checkValidPhone($event)"
									/>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- City/Province -->
					<div class="form-group question__birthyear">
						<label for="birthyear" class="form-group__label">{{
							$t("ProductBooking.Address")
						}}</label>
						<div class="form-group__input">
							<ValidationProvider
								v-slot="{ classes, errors }"
								name="booking_address"
								rules="required"
							>
								<div :class="[classes]">
									<multiselect
										v-model="selectedAddress"
										:options="listAddress"
										:custom-label="labelAddress"
										:searchable="false"
										:close-on-select="true"
										:show-labels="false"
										placeholder="Vui lòng chọn"
										:disabled="false"
									>
									</multiselect>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- Service -->
					<div class="form-group question__service" stretch>
						<label for="question-3" class="form-group__label">{{
							$t("BookingQuestion3.Question")
						}}</label>
						<ValidationProvider
							v-slot="{ classes, errors }"
							name="booking_service"
							rules="required"
						>
							<div :class="['form-group__input', classes]">
								<div v-for="answer in getAnswerQuestion3()" :key="answer.id" class="form-check">
									<input
										:id="answer.id"
										v-model="bookingForm.service"
										class="form-check-input"
										type="checkbox"
										:value="answer.value"
									/>
									<label class="form-check-label" :for="answer.id">{{ answer.label }}</label>
								</div>
								<div class="error-mess">{{ errors[0] }}</div>
							</div>
						</ValidationProvider>
					</div>

					<!-- Receive method -->
					<div v-if="isHNService" class="form-group question__method" stretch>
						<label for="question-4" class="form-group__label">{{
							$t("BookingQuestion4.Question")
						}}</label>
						<ValidationProvider
							v-slot="{ classes, errors }"
							name="booking_receive_method"
							:rules="{ required: isHNService }"
						>
							<div :class="['form-group__input grid', classes]">
								<div v-for="answer in answersQ4" :key="answer.id" class="form-check">
									<input
										:id="answer.id"
										v-model="bookingForm.receiveMethod"
										class="form-check-input"
										type="radio"
										name="answerQuestion4"
										:value="answer.value"
									/>
									<label class="form-check-label" :for="answer.id">{{ answer.label }}</label>
								</div>
								<div class="error-mess">{{ errors[0] }}</div>
							</div>
						</ValidationProvider>
					</div>

					<!-- Address -->
					<div v-if="isHNService" class="form-group question__address" stretch>
						<div v-if="!isOraQuickClinic">
							<label for="address" class="form-group__label">{{
								$t("ProductBooking.DeliveryAddress")
							}}</label>
							<ValidationProvider
								ref="address"
								v-slot="{ classes, errors }"
								name="booking_receive_address"
								:rules="{ required: isHNService, max: maxLengthAddress }"
							>
								<div :class="['form-group__input', classes]">
									<textarea
										id="address"
										ref="addressText"
										v-model.trim="bookingForm.receiveAddress"
										rows="1"
										:maxlength="maxLengthAddress"
										class="form-control other"
										autocomplete="address"
										@input="resizeTextArea('addressText')"
									></textarea>

									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>

						<div v-else class="form-group question__address" stretch>
							<label for="question-6" class="form-group__label">{{
								$t("BookingQuestion6.Question")
							}}</label>
							<ValidationProvider
								v-slot="{ classes, errors }"
								name="booking_clinic"
								rules="required"
							>
								<div :class="['form-group__input grid', classes]">
									<div v-for="answer in answersQ6" :key="answer.id" class="form-check">
										<input
											:id="answer.id"
											v-model="bookingForm.clinic"
											class="form-check-input"
											type="radio"
											name="answerQuestion6"
											:value="answer.value"
										/>
										<label class="form-check-label" :for="answer.id">{{ answer.label }}</label>
									</div>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- PrEP -->
					<div v-if="isPrEPService" class="form-group question__prep" stretch>
						<label for="addressPrepService" class="form-group__label">{{
							$t("BookingQuestion7.Question")
						}}</label>
						<multiselect
							id="addressPrepService"
							v-model.trim="bookingForm.prepAddress"
							:options="listClinics"
							:close-on-select="true"
							:show-labels="false"
							:allow-empty="false"
							:internal-search="false"
							:searchable="true"
							placeholder=""
							track-by="id"
							label="name"
							@open="getClinic"
							@close="checkValidate('address')"
							@search-change="searchChange('address', $event)"
						>
							<template slot="singleLabel" slot-scope="props"
								><span class="option__desc"
									><span class="option__title option__name d-block">{{ props.option.name }}</span>
									<span class="option__title">{{ props.option.address }}</span></span
								></template
							>
							<template slot="option" slot-scope="props">
								<div class="option__desc"
									><span class="option__title option__name d-block">{{ props.option.name }}</span
									><span class="option__title option__address">{{
										props.option.address
									}}</span></div
								>
							</template>
							<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
							<template #noResult>{{ $t("DropDown.NoResult") }}</template>
						</multiselect>
					</div>
				</div>

				<address class="more-info">
					<label class="more-info__head">{{ $t("ProductBooking.MoreInfo") }}</label>
					<ul>
						<li
							>{{ $t("ProductBooking.Website") }}
							<a :href="wecareURL" target="_blank">{{ $t("ProductBooking.WeCare") }}</a></li
						>
						<li
							>{{ $t("ProductBooking.Facebook") }}
							<a :href="xomFacebookURL" target="_blank">{{
								$t("ProductBooking.XomCauVong")
							}}</a></li
						>
					</ul>
				</address>

				<hr class="h-divider" />

				<div class="text-right">
					<CButton color="info" type="submit">{{ $t("ProductBooking.Finish") }}</CButton>
				</div>
			</form>
		</ValidationObserver>
	</section>
</template>

<script>
import { BOOKING_SERVICES, BOOKING_ORAQUICK, BOOKING_CLINIC } from "@/shared/plugins/constants"
import { ORDER_PRODUCT, GET_CLINIC } from "./../store/action-types"
import { createNamespacedHelpers } from "vuex"
import { ADDRESS, CITYID } from "../../../shared/plugins"

const { mapActions, mapState } = createNamespacedHelpers("productBooking")
export default {
	name: "BookingForm",

	props: {
		fullName: {
			type: [String, Number],
			default: null,
		},
		birthYear: {
			type: [String, Number],
			default: null,
		},
		phoneNumber: {
			type: [String, Number],
			default: null,
		},
	},

	data() {
		return {
			phoneMaxLength: process.env.VUE_APP_PHONE_MAX_LENGTH,
			maxlengthBirth: process.env.VUE_APP_BIRTH_YEAR,
			maxLength: process.env.VUE_APP_INPUT_MAX_LENGTH,
			maxLengthAddress: process.env.VUE_APP_ADDRESS_MAX_LENGTH,
			wecareURL: process.env.VUE_APP_WECARE_URL,
			xomFacebookURL: process.env.VUE_APP_XOMCAUVONG_FACEBOOK_URL,

			isMobile: false,
			listClinics: [],
			listAddress: [ADDRESS.HN, ADDRESS.DONGNAI, ADDRESS.HCM],
			selectedAddress: null,

			bookingForm: {
				fullName: null,
				phoneNumber: null,
				birthYear: null,
				service: [],
				receiveMethod: null,
				receiveAddress: null,
				prepAddress: null,
				clinic: null,
			},

			answersQ3: [
				{
					id: "q3-check-1",
					label: this.$t("BookingQuestion3.Answer1"),
					value: BOOKING_SERVICES.HA_NOI,
				},
				{
					id: "q3-check-2",
					label: this.$t("BookingQuestion3.Answer2"),
					value: BOOKING_SERVICES.FREE_PREP,
				},
				{
					id: "q3-check-3",
					label: this.$t("BookingQuestion3.Answer3"),
					value: BOOKING_SERVICES.NO_SERVICES,
				},
			],

			answersQ4: [
				{
					id: "q4-check-1",
					label: this.$t("BookingQuestion4.Answer1"),
					value: BOOKING_ORAQUICK.POST,
				},
				{
					id: "q4-check-2",
					label: this.$t("BookingQuestion4.Answer2"),
					value: BOOKING_ORAQUICK.GRAB,
				},
				{
					id: "q4-check-3",
					label: this.$t("BookingQuestion4.Answer3"),
					value: BOOKING_ORAQUICK.CLINIC,
				},
			],

			answersQ6: [
				{
					id: "q6-check-1",
					label: this.$t("BookingQuestion6.Answer1"),
					value: BOOKING_CLINIC.GLINK_HN,
				},
				{
					id: "q6-check-2",
					label: this.$t("BookingQuestion6.Answer2"),
					value: BOOKING_CLINIC.BIEN_VIET,
				},
				{
					id: "q6-check-3",
					label: this.$t("BookingQuestion6.Answer3"),
					value: BOOKING_CLINIC.VENUS_HN,
				},
				{
					id: "q6-check-4",
					label: this.$t("BookingQuestion6.Answer4"),
					value: BOOKING_CLINIC.ALOCARE_HCM,
				},
				{
					id: "q6-check-5",
					label: this.$t("BookingQuestion6.Answer5"),
					value: BOOKING_CLINIC.GLINK_HCM,
				},
				{
					id: "q6-check-6",
					label: this.$t("BookingQuestion6.Answer6"),
					value: BOOKING_CLINIC.ALOCARE_DONGNAI,
				},
				{
					id: "q6-check-7",
					label: this.$t("BookingQuestion6.Answer7"),
					value: BOOKING_CLINIC.GLINK_DONGNAI,
				},
			],
		}
	},

	computed: {
		...mapState(["clinic"]),

		isHNService: function () {
			return this.bookingForm.service.includes(BOOKING_SERVICES.HA_NOI)
		},

		isOraQuickClinic: function () {
			return this.bookingForm.receiveMethod === BOOKING_ORAQUICK.CLINIC
		},

		isPrEPService: function () {
			return this.bookingForm.service.includes(BOOKING_SERVICES.FREE_PREP)
		},
	},

	watch: {
		clinic: function (val) {
			if (this.selectedAddress != null) {
				val = val.filter(item => {
					if (
						item.cityId === this.getCityId(this.selectedAddress) &&
						(item.type === "TU_NHAN" || item.type === "CONG")
					)
						return item
				})
			}
			this.listClinics = [...val]
		},

		isOraQuickClinic: function () {
			const address = this.bookingForm.receiveAddress
			this.bookingForm.receiveAddress = null

			if (address) this.$refs.address.reset()
		},
		selectedAddress: function (newVal) {
			this.setListAddressByCity(newVal)
		},
	},

	created() {
		this.setUpData()
	},

	mounted() {
		// Get data from iframe
		window.addEventListener("message", this.setDataFromIframe, false)
	},

	beforeDestroy() {
		window.removeEventListener("message", this.setDataFromIframe)
	},

	methods: {
		...mapActions({ ORDER_PRODUCT, GET_CLINIC }),

		setUpData() {
			if (!this.isMobile) {
				const info = JSON.parse(localStorage.getItem("riskInfo"))

				if (info?.phoneNumber || info?.birthYear) {
					this.bookingForm.phoneNumber = info?.phoneNumber
					this.bookingForm.birthYear = info?.birthYear
				}
			}
		},

		applyForm() {
			const dataForm = { ...this.bookingForm }
			const dataParams = {
				fullName: dataForm.fullName,
				phoneNumber: dataForm.phoneNumber,
				birthYear: Number(dataForm.birthYear),
				service: dataForm.service,
				receiveMethod: dataForm.receiveMethod,
				clinic: dataForm.clinic,
				isCompletedRisk: false,
			}

			if (typeof dataForm.receiveAddress === "object" && dataForm.receiveAddress !== null) {
				dataParams.receiveAddress = dataForm.receiveAddress.address
			} else {
				dataParams.receiveAddress = dataForm.receiveAddress
			}

			if (typeof dataForm.prepAddress === "object" && dataForm.prepAddress !== null) {
				dataParams.prepAddress = dataForm.prepAddress.address
			} else {
				dataParams.prepAddress = dataForm.prepAddress
			}

			let isCompletedRisk = localStorage.getItem("isCompletedRisk")
			if (isCompletedRisk === "true" || isCompletedRisk === true) dataParams.isCompletedRisk = true

			this.ORDER_PRODUCT(dataParams)
		},
		getAnswerQuestion3() {
			if (this.selectedAddress === ADDRESS.HCM) {
				let answersQ3_HCM = [
					{
						id: "q3-check-1",
						label: this.$t("BookingQuestion3.Answer1_HCM"),
						value: BOOKING_SERVICES.HA_NOI,
					},
					{
						id: "q3-check-2",
						label: this.$t("BookingQuestion3.Answer2"),
						value: BOOKING_SERVICES.FREE_PREP,
					},
					{
						id: "q3-check-3",
						label: this.$t("BookingQuestion3.Answer3"),
						value: BOOKING_SERVICES.NO_SERVICES,
					},
				]
				return answersQ3_HCM
			} else if (this.selectedAddress === ADDRESS.DONGNAI) {
				let answersQ3_DONGNAI = [
					{
						id: "q3-check-2",
						label: this.$t("BookingQuestion3.Answer2"),
						value: BOOKING_SERVICES.FREE_PREP,
					},
					{
						id: "q3-check-3",
						label: this.$t("BookingQuestion3.Answer3"),
						value: BOOKING_SERVICES.NO_SERVICES,
					},
				]
				return answersQ3_DONGNAI
			} else {
				return this.answersQ3
			}
		},
		getClinic() {
			this.GET_CLINIC()
		},
		checkValidate(field) {
			if (this.$refs[field]) this.$refs[field].validate()
		},

		searchChange(field, value) {
			if (!value || !value.length) {
				this.listClinics = [...this.clinic]
				return
			}

			const valueSearch = this.nomalizeText(value)
			this.listClinics = this.clinic.filter(({ name, address }) => {
				return (
					this.nomalizeText(name).includes(valueSearch) ||
					this.nomalizeText(address).includes(valueSearch)
				)
			})

			if (this.$refs[field] && value.length) this.$refs[field].reset()
			else this.checkValidate(field)
		},

		setDataFromIframe(event) {
			if (event.data && event.data.idName === "pass-params") {
				if (!this.isAdmin) {
					// Fill user info from mobile device
					this.bookingForm.fullName = event.data.params.fullname
					this.bookingForm.phoneNumber = event.data.params.phone
					this.bookingForm.birthYear = event.data.params.birth

					this.isMobile = true
					window.removeEventListener("message", this.setDataFromIframe)
				}
			}
		},

		resizeTextArea(field) {
			const ref = this.$refs[field]
			ref.style.height = "38px"
			ref.style.height = ref.scrollHeight + "px"

			if (this.bookingForm.receiveAddress.length) this.$refs.address.reset()
		},
		labelAddress(value) {
			return {
				[ADDRESS.HN]: this.$t("Address.HANOI"),
				[ADDRESS.DONGNAI]: this.$t("Address.DONGNAI"),
				[ADDRESS.HCM]: this.$t("Address.HCM"),
			}[value]
		},
		getCityId(value) {
			return {
				[ADDRESS.HN]: CITYID.HN,
				[ADDRESS.DONGNAI]: CITYID.DONGNAI,
				[ADDRESS.HCM]: CITYID.HCM,
			}[value]
		},
		setListAddressByCity(address) {
			if (address === ADDRESS.HN) {
				this.answersQ6 = [
					{
						id: "q6-check-1",
						label: this.$t("BookingQuestion6.Answer1"),
						value: BOOKING_CLINIC.GLINK_HN,
					},
					{
						id: "q6-check-2",
						label: this.$t("BookingQuestion6.Answer2"),
						value: BOOKING_CLINIC.BIEN_VIET,
					},
					{
						id: "q6-check-3",
						label: this.$t("BookingQuestion6.Answer3"),
						value: BOOKING_CLINIC.VENUS_HN,
					},
				]
			}
			if (address === ADDRESS.DONGNAI) {
				this.answersQ6 = [
					{
						id: "q6-check-6",
						label: this.$t("BookingQuestion6.Answer6"),
						value: BOOKING_CLINIC.ALOCARE_DONGNAI,
					},
					{
						id: "q6-check-7",
						label: this.$t("BookingQuestion6.Answer7"),
						value: BOOKING_CLINIC.GLINK_DONGNAI,
					},
				]
			}
			if (address === ADDRESS.HCM) {
				this.answersQ6 = [
					{
						id: "q6-check-4",
						label: this.$t("BookingQuestion6.Answer4"),
						value: BOOKING_CLINIC.ALOCARE_HCM,
					},
					{
						id: "q6-check-5",
						label: this.$t("BookingQuestion6.Answer5"),
						value: BOOKING_CLINIC.GLINK_HCM,
					},
				]
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";

.booking-form {
	@include form;
	@include font-lato;

	&__heading {
		font-size: 16px;
		font-weight: 400;
		color: $color-black;
	}

	&__container {
		display: grid;
		grid-gap: 10px 20px;
		width: 95%;

		// padding: 28px 24px 32px;
		background-color: $color-white;
		border-radius: 12px;

		@media (min-width: $xxs) {
			grid-template-columns: 1fr 1fr;
		}
	}
}

textarea {
	resize: none;
	overflow: hidden;
	min-height: 38px;
	max-height: 150px;
}

.other {
	@include input-other;
	border-radius: 5px 5px 0 0;
}

.form-group {
	margin-bottom: 0;

	&[stretch] {
		grid-column: 1 / -1;
	}

	&__label {
		margin-bottom: 0px;
		font-weight: bold;
		color: $color-gray;
		font-size: 16px;

		&[required]::after {
			content: " *";
			color: $color-red;
		}
	}

	&__input {
		&--description /deep/ .textarea__control {
			height: 132px;
		}
	}
}

.form-check {
	.form-check-label {
		font-size: 16px;
	}
}

.grid {
	@include grid-between;
	grid-template-columns: 1fr;

	.error-mess {
		grid-column: 1 / -1;
	}
}

.question {
	&__method .grid {
		@media (min-width: $xs) {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	&__phone .grid {
		grid-template-columns: 100px 1fr;
	}

	&__address /deep/ .multiselect__tags {
		background: $color-white;
	}
}

.more-info {
	margin-top: 24px;
	background: $color-snow;
	padding: 16px;
	font-size: 14px;

	&__head {
		font-weight: 500;
		color: $color-gray;
		font-size: 16px;
	}

	ul {
		padding-left: 18px;

		li {
			position: relative;

			a {
				position: absolute;
				left: 96px;
				color: $color-blue;
			}
		}
	}
}

/deep/ .multiselect {
	min-height: 35px;

	&__single,
	&__option {
		white-space: normal;
		display: flex;
		gap: 4px;

		&--highlight:hover,
		&--selected {
			.option__name {
				color: $color-primary !important;
			}
		}
	}

	&__tags {
		padding-top: 6px;
		min-height: 38px;
		border: none;
		border-radius: 5px 5px 0 0;
		border-bottom: 1px solid $color-gray80;
	}

	&__select {
		height: 35px;
	}

	&__single {
		background: rgba(38, 34, 48, 0);
	}

	.option {
		&__image {
			width: 20px;
			height: 20px;
			border-radius: 50%;
			object-fit: cover;
		}

		&__desc {
			align-self: center;
		}

		&__name {
			color: $color-dark-blue;
			font-weight: 600;
		}

		&__address {
			display: block;
			word-break: break-all;
		}
	}
}
</style>
